import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation } from "swiper";
import { Link } from "gatsby-plugin-react-intl";
import { StaticImage } from "gatsby-plugin-image";

import "swiper/css";
//import "swiper/css/pagination";
import "swiper/css/navigation";
import "./IntroSlider.css";

//SwiperCore.use([Pagination, Autoplay]);
SwiperCore.use([Navigation, Autoplay]);

function SwiperContent({ headline = "", subheadline = "", img = false, cta = false, children = "" }) {
  return (
    <div className="md:flex xl:flex-col">
      <div className="flex text-left items-center mb-6 md:shrink-0 md:items-start">
        <div className="flex items-start md:flex-col md:items-center xl:flex-row xl:items-start">
          {img && (
            <img
              className={img.classNames || "w-24 h-24 rounded-lg shadow-lg md:w-32 md:h-32 xl:w-24 xl:h-24 2xl:w-32 2xl:h-32"}
              src={img.src}
              alt=""
            />
          )}
          <div className="ml-6 md:text-center md:mx-0 md:mt-4 xl:text-left xl:mt-0 xl:ml-6">
            <h1 className="text-xl text-slate-50 font-bold xl:text-3xl">{headline}</h1>
            <h3 className="text-md text-slate-100 2xl:text-lg max-w-xs">{subheadline}</h3>
          </div>
        </div>
        {cta && (
          <div className="hidden m-auto mr-0 sm:inline-block md:hidden xl:inline-block 2xl:hidden">
            <Link to={cta.href}>
              <button className="cta-button ml-4">{cta.text}</button>
            </Link>
          </div>
        )}
      </div>
      <div className="md:ml-12 xl:mx-0 2xl:text-lg slide-content">
        {children}
        {cta && (
          <div className="sm:hidden md:inline-block xl:hidden 2xl:inline-block">
            <Link to={cta.href}>
              <button className="cta-button">{cta.text}</button>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}

export default function IntroSlider({ intl }) {
  const [activeSlide, setActiveSlide] = useState(0);

  useEffect(() => {
    const slides = [...document.getElementById("map__map").getElementsByClassName("satimgslideshow")];
    slides.forEach(({ style }) => {
      style.opacity = 0;
    });
    if (activeSlide === 5) {
      slides.forEach(({ style }, i) => {
        setTimeout(() => {
          style.opacity = 1;
        }, 1500 * i);
      });
    }
  }, [activeSlide]);

  return (
    <div className="max-w-screen-2xl mx-auto flex flex-col xl:flex-row-reverse">
      <div className="dots 2xl:rounded-br-3xl 2xl:shadow-lg w-full max-w-full min-h-0 min-w-0">
        <Swiper
          navigation={true}
          onSlideChange={({ realIndex }) => {
            setActiveSlide(realIndex);
          }}
          loop={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: true,
          }}
          speed={1000}
        >
          <SwiperSlide>
            <SwiperContent
              headline={intl.formatMessage(
                { id: "map.slide-1.headline", defaultMessage: "<mark>Flood Hazards</mark>" },
                { mark: (text) => <mark>{text}</mark> }
              )}
              subheadline={intl.formatMessage({
                id: "map.slide-1.subheadline",
                defaultMessage: "Get flood hazard scores on the individual property level!",
              })}
              img={{
                src: "https://images.unsplash.com/photo-1604275689235-fdc521556c16?fit=crop&w=128&h=128&q=80",
              }}
              cta={{ text: intl.formatMessage({ id: "map.cta.learnmore", defaultMessage: "Learn More" }), href: "/asset-data" }}
            >
              {intl.formatMessage(
                {
                  id: "map.slide-1.description",
                  defaultMessage:
                    "<p>We create the most accurate and up-to-date flood hazard quantification service available by fusing existing flood data and satellite imagery.</p>",
                },
                { p: (text) => <p>{text}</p> }
              )}
            </SwiperContent>
          </SwiperSlide>
          <SwiperSlide>
            <SwiperContent
              headline={intl.formatMessage(
                { id: "map.slide-2.headline", defaultMessage: "<mark>Asset Data</mark>" },
                { mark: (text) => <mark>{text}</mark> }
              )}
              subheadline={intl.formatMessage({
                id: "map.slide-2.subheadline",
                defaultMessage: "Know your portfolio",
              })}
              img={{
                src: "https://images.unsplash.com/photo-1538688273852-e29027c0c176?fit=crop&w=128&h=128&q=80",
              }}
              cta={{ text: intl.formatMessage({ id: "map.cta.learnmore", defaultMessage: "Learn More" }), href: "/asset-data" }}
            >
              {intl.formatMessage(
                {
                  id: "map.slide-2.description",
                  defaultMessage:
                    "<p>Say Goodbye to information gaps and know your portfolio!</p><p>We provide the possibility to create, autocomplete and maintain real estate portfolio databases.</p>",
                },
                { p: (text) => <p>{text}</p> }
              )}
            </SwiperContent>
          </SwiperSlide>
          <SwiperSlide>
            <SwiperContent
              headline={intl.formatMessage(
                { id: "map.slide-3.headline", defaultMessage: "<mark>Offline Leads</mark>" },
                { mark: (text) => <mark>{text}</mark> }
              )}
              subheadline={intl.formatMessage({
                id: "map.slide-3.subheadline",
                defaultMessage: "Beat the Competition",
              })}
              img={{
                src: "https://images.unsplash.com/photo-1627019866926-b6030ff785fe?fit=crop&w=128&h=128&q=80",
              }}
              cta={{
                text: intl.formatMessage({ id: "map.cta.learnhowitworks", defaultMessage: "Learn how it works" }),
                href: "/satellite-powered-leads",
              }}
            >
              {intl.formatMessage(
                {
                  id: "map.slide-3.description",
                  defaultMessage:
                    "<p>You run an e-commerce business and your clients live in or build on properties with specific characteristics, such as a pool or a garden, or an ongoing construction?</p><p>You are frustrated with online marketing platforms?</p><p>Learn how ubicube can help you to reach your customers!</p>",
                },
                { p: (text) => <p>{text}</p> }
              )}
            </SwiperContent>
          </SwiperSlide>
          <SwiperSlide>
            <SwiperContent
              headline={intl.formatMessage(
                { id: "map.slide-4.headline", defaultMessage: "<mark>Off-market properties</mark>" },
                { mark: (text) => <mark>{text}</mark> }
              )}
              subheadline={intl.formatMessage({
                id: "map.slide-4.subheadline",
                defaultMessage: "Find interesting properties before they are on sale",
              })}
              img={{
                src: "https://images.unsplash.com/photo-1628133287836-40bd5453bed1?fit=crop&w=128&h=128&q=80",
              }}
              cta={{
                text: intl.formatMessage({ id: "map.cta.learnhowitworks", defaultMessage: "Learn how it works" }),
                href: "/satellite-powered-leads",
              }}
            >
              {intl.formatMessage(
                {
                  id: "map.slide-4.description",
                  defaultMessage:
                    "<p>ubicube's technology lets you systematically search for properties that fit customized criteria and to get in touch with their owners. <p></p>You don't believe us? We can explain how it works!</p>",
                },
                { p: (text) => <p>{text}</p> }
              )}
            </SwiperContent>
          </SwiperSlide>
          <SwiperSlide>
            <SwiperContent
              headline={intl.formatMessage(
                { id: "map.slide-5.headline", defaultMessage: "<mark>Urban development trends</mark>" },
                { mark: (text) => <mark>{text}</mark> }
              )}
              subheadline={intl.formatMessage({
                id: "map.slide-5.subheadline",
                defaultMessage: "Get real insights on neighborhood developments",
              })}
              img={{
                src: "https://images.unsplash.com/photo-1618990908950-fd1a23294d11?fit=crop&w=128&h=128&q=80",
              }}
              cta={{
                text: intl.formatMessage({ id: "map.cta.learnmore", defaultMessage: "Learn More" }),
                href: "/neighborhood-insights",
              }}
            >
              {intl.formatMessage(
                {
                  id: "map.slide-5.description",
                  defaultMessage:
                    "<p>Need a crystal ball that tells us which is the next up-and-coming neighborhood?</p><p>ubicube combines data from thousands of sources to model trends in gentrification, urban sprawl and demographics.</p>",
                },
                { p: (text) => <p>{text}</p> }
              )}
            </SwiperContent>
          </SwiperSlide>
          <SwiperSlide>
            <SwiperContent
              headline={intl.formatMessage(
                { id: "map.slide-6.headline", defaultMessage: "<mark>On-demand Satellite Images</mark>" },
                { mark: (text) => <mark>{text}</mark> }
              )}
              subheadline={intl.formatMessage({
                id: "map.slide-6.subheadline",
                defaultMessage: "Get a current birds-eye view on your properties",
              })}
              img={{
                src: "https://images.unsplash.com/photo-1514864151880-d1bef4892f29?fit=crop&w=128&h=128&q=80",
              }}
              cta={{
                text: intl.formatMessage({ id: "map.cta.learnmore", defaultMessage: "Learn More" }),
                href: "/satellite-imagery",
              }}
            >
              {intl.formatMessage(
                {
                  id: "map.slide-6.description",
                  defaultMessage:
                    "<p>When you think of satellite images, you think of Google Maps?</p><p>Most of the time, publicly available satellite images are outdated and not suitable for your purpose.</p><p>We can provide you with high-resolution satellite imagery for your area of interest.</p><p>If you like, over a period of time.</p>",
                },
                { p: (text) => <p>{text}</p> }
              )}
            </SwiperContent>
          </SwiperSlide>
          <SwiperSlide>
            <SwiperContent
              headline={intl.formatMessage(
                { id: "map.slide-7.headline", defaultMessage: "<mark>ubicube Consulting</mark>" },
                { mark: (text) => <mark>{text}</mark> }
              )}
              subheadline={intl.formatMessage({
                id: "map.slide-7.subheadline",
                defaultMessage: "Talk to an Expert",
              })}
              img={{
                src: "https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?fit=crop&w=128&h=128&q=80",
              }}
              cta={{
                text: intl.formatMessage({ id: "map.cta.bookasession", defaultMessage: "Book a Free Session" }),
                href: "/contact-us",
              }}
            >
              {intl.formatMessage(
                {
                  id: "map.slide-7.description",
                  defaultMessage:
                    "<p>You have an idea how to make better use of (your) geodata for your business, but you are unsure if it is possible?</p><p>Our experts can help!</p>",
                },
                { p: (text) => <p>{text}</p> }
              )}
            </SwiperContent>
          </SwiperSlide>
        </Swiper>
      </div>
      <div id="map__map">
        <div className="dots w-full pb-[60%] xl:w-[750px] 2xl:w-[1000px] 2xl:rounded-tl-3xl">
          <div
            className="absolute w-full xl:w-[750px] 2xl:w-[1000px] 2xl:rounded-tl-3xl transition duration-1000 opacity-0"
            style={{ opacity: activeSlide === 0 && 1 }}
          >
            <StaticImage src="flood_score_1500x900.png" aspectRatio={750 / 450} layout="fullWidth" alt="" className="w-full 2xl:rounded-tl-3xl" />
            <img src="/swiper/slide_floodhazard.png" alt="" className="absolute inset-0 w-full h-full" />
          </div>
          <div
            className="absolute w-full xl:w-[750px] 2xl:w-[1000px] 2xl:rounded-tl-3xl transition duration-1000 opacity-0"
            style={{ opacity: activeSlide === 1 && 1 }}
          >
            <StaticImage src="asset_data_1500x900.png" aspectRatio={750 / 450} layout="fullWidth" alt="" className="w-full 2xl:rounded-tl-3xl" />
            <img src="/swiper/slide_assetdata.png" alt="" className="absolute inset-0 w-full h-full" />
          </div>
          <div
            className="absolute w-full xl:w-[750px] 2xl:w-[1000px] 2xl:rounded-tl-3xl transition duration-1000 opacity-0"
            style={{ opacity: activeSlide === 2 && 1 }}
          >
            <StaticImage src="offline_lead_1500x900.png" aspectRatio={750 / 450} layout="fullWidth" alt="" className="w-full 2xl:rounded-tl-3xl" />
            <img src="/swiper/slide_offlineleads.png" alt="" className="absolute inset-0 w-full h-full" />
          </div>
          <div
            className="absolute w-full xl:w-[750px] 2xl:w-[1000px] 2xl:rounded-tl-3xl transition duration-1000 opacity-0"
            style={{ opacity: activeSlide === 3 && 1 }}
          >
            <StaticImage src="plot_finder_1500x900.png" aspectRatio={750 / 450} layout="fullWidth" alt="" className="w-full 2xl:rounded-tl-3xl" />
            <img src="/swiper/slide_plotfinder.png" alt="" className="absolute inset-0 w-full h-full" />
          </div>
          <div
            className="absolute w-full xl:w-[750px] 2xl:w-[1000px] 2xl:rounded-tl-3xl transition duration-1000 opacity-0"
            style={{ opacity: activeSlide === 4 && 1 }}
          >
            <StaticImage
              src="neighborhood_insights_1500x900.png"
              aspectRatio={750 / 450}
              layout="fullWidth"
              alt=""
              className="w-full 2xl:rounded-tl-3xl"
            />
            <img src="/swiper/slide_neighborhood.png" alt="" className="absolute inset-0 w-full h-full" />
          </div>
          <div
            className="absolute w-full xl:w-[750px] 2xl:w-[1000px] 2xl:rounded-tl-3xl transition duration-1000 opacity-0"
            style={{ opacity: activeSlide === 5 && 1 }}
          >
            <StaticImage
              src="sat_image_1_1500x900.png"
              aspectRatio={750 / 450}
              layout="fullWidth"
              alt=""
              className="w-full transition 2xl:rounded-tl-3xl duration-1000"
              style={{ position: "absolute" }}
            />
            <StaticImage
              src="sat_image_2_1500x900.png"
              aspectRatio={750 / 450}
              layout="fullWidth"
              alt=""
              className="w-full transition 2xl:rounded-tl-3xl duration-1000 satimgslideshow"
              style={{ position: "absolute" }}
            />
            <StaticImage
              src="sat_image_3_1500x900.png"
              aspectRatio={750 / 450}
              layout="fullWidth"
              alt=""
              className="w-full transition 2xl:rounded-tl-3xl duration-1000 satimgslideshow"
              style={{ position: "absolute" }}
            />
            <StaticImage
              src="sat_image_4_1500x900.png"
              aspectRatio={750 / 450}
              layout="fullWidth"
              alt=""
              className="w-full transition 2xl:rounded-tl-3xl duration-1000 satimgslideshow"
              style={{ position: "absolute" }}
            />
            <StaticImage
              src="sat_image_5_1500x900.png"
              aspectRatio={750 / 450}
              layout="fullWidth"
              alt=""
              className="w-full transition 2xl:rounded-tl-3xl duration-1000 satimgslideshow"
              style={{ position: "absolute" }}
            />
          </div>
          <div
            className="absolute w-full xl:w-[750px] 2xl:w-[1000px] 2xl:rounded-tl-3xl transition duration-1000 opacity-0"
            style={{ opacity: activeSlide === 6 && 1 }}
          >
            <StaticImage src="consulting_1500.jpg" aspectRatio={750 / 450} layout="fullWidth" alt="" className="w-full 2xl:rounded-tl-3xl" />
          </div>
        </div>
      </div>
    </div>
  );
}
