import React from "react";
import { GatsbyImage as Image } from "gatsby-plugin-image";
import { FormattedDate, Link } from "gatsby-plugin-react-intl";

export function BlogStubHomeWrapper({ intl, children }) {
  return (
    <div className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
      <div className="absolute inset-0">
        <div className="bg-white h-1/3 sm:h-2/3" />
      </div>
      <div className="relative max-w-7xl mx-auto">
        <div className="text-center">
          <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
            {intl.formatMessage({ id: "blog.heading-featured-articles", defaultMessage: "Featured Blog Articles" })}
          </h2>
        </div>
        <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">{children}</div>
      </div>
    </div>
  );
}

export function BlogStub({ intl, post }) {
  return (
    <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
      <div className="flex-shrink-0">
        <Image
          className="h-48 w-full object-cover"
          image={post.frontmatter.featureImage.childImageSharp.gatsbyImageData}
          alt={post.frontmatter.featureImageAlt}
        ></Image>
      </div>
      <div className="flex-1 bg-white p-6 flex flex-col justify-between">
        <div className="flex-1">
          <p className="text-sm font-medium uppercase text-uc-600">{post.frontmatter.category}</p>
          <Link to={`${post.fields.slug}`} className="block mt-2">
            <p className="text-xl font-semibold text-gray-900">{post.frontmatter.title}</p>
            <p className="mt-3 text-base text-gray-500">{post.excerpt}</p>
          </Link>
        </div>
        <div className="mt-6 flex items-center">
          <div className="flex-shrink-0">
            <Link to="/team">
              <span className="sr-only">{post.frontmatter.author}</span>
              <img className="h-10 w-10 rounded-full" src={`/team/uc-as-portrait.png`} alt="" />
            </Link>
          </div>
          <div className="ml-3">
            <p className="text-sm font-medium text-gray-900">
              <Link to="/team" className="hover:underline">
                {post.frontmatter.author}
              </Link>
            </p>
            <div className="flex space-x-1 text-sm text-gray-500">
              <FormattedDate value={post.frontmatter.date} year="numeric" month="long" day="2-digit" />
              <span className="mx-1" aria-hidden="true">
                &middot;
              </span>
              <span>
                {post.timeToRead}min {intl.formatMessage({ id: "blog.timetoread", defaultMessage: "read" })}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
