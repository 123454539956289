import React from "react";
import Layout from "../components/Layout";
import Seo from "../components/SEO";
import { graphql } from "gatsby";
import { useIntl, FormattedMessage, Link } from "gatsby-plugin-react-intl";
import { getAllResolvedVersionsForLanguage } from "../utilities/Node";
import { BlogStubHomeWrapper, BlogStub } from "../components/BlogStub";
import { LogoReel, LogoReelItem } from "../components/LogoReel";
// import SVGMap from "../components/SVGMap/SVGMap";

import ContactUsBar from "../components/ContactUsBar";
import IntroSlider from "../components/IntroSwiper/IntroSlider";

// import { BgImage } from "gbimage-bridge";
export const pageQuery = graphql`
  query {
    mainSplashImage: file(relativePath: { eq: "hk-victoria-harbor-widescreen.jpg" }) {
      ...HomePageSplashImage
    }
    allMdx(
      filter: {
        fields: {
          slug: {
            in: [
              "/blog/2021-06-10-vienna-a-growing-city-europe/"
              "/blog/2021-08-04-covid/"
              "/blog/2022-17-03-satellite-images-beyond-google-earth/"
            ]
          }
        }
      }
    ) {
      ...FeaturedPost
    }
  }
`;

const IndexPage = ({ data }) => {
  const intl = useIntl();
  const posts = getAllResolvedVersionsForLanguage(data, intl);
  return (
    <Layout intl={intl} id={"page-index"}>
      <Seo title="Home" />
      <section className="max-w-screen-2xl mx-auto my-8">
        <div className="text-center">
          <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
            {intl.formatMessage(
              { id: "index.main-headline", defaultMessage: "<mark>Crunching Geodata</mark> to Boost your Business" },
              { mark: (text) => <mark>{text}</mark> }
            )}
          </h2>
          <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
            <FormattedMessage
              id="index.headline-description"
              defaultMessage="Ubicube can help you leverage the full potential of geodata. Explore our showreel to find out how ubicube can make your life easier."
            />
          </p>
        </div>
      </section>
      <IntroSlider intl={intl} />
      <section className="max-w-screen-2xl mx-auto mt-16">
        <div>
          <BlogStubHomeWrapper intl={intl}>
            <BlogStub intl={intl} post={posts[0]} />
            <BlogStub intl={intl} post={posts[1]} />
            <BlogStub intl={intl} post={posts[2]} />
          </BlogStubHomeWrapper>
        </div>
      </section>

      <section className="max-w-screen-2xl mx-auto my-20">
        <div className="text-center">
          <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
            <FormattedMessage id="index.heading-customers" defaultMessage="Our Customers and Partners" />
          </h2>
          <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
            {intl.formatMessage({ id: "index.heading-customers-subtext", defaultMessage: "ubicube's valued network of partners and lead customers" })}
          </p>
        </div>
        <LogoReel>
          <LogoReelItem src="/customer_logos/viadonau_logo.png" alt="Viadonau Logo" />
          <LogoReelItem src="/customer_logos/ESA_BIC_Logo.png" alt="ESA BIC Logo" />
          <LogoReelItem src="/customer_logos/ffg_logo_en.svg" alt="FFG Logo" />
          <LogoReelItem src="/customer_logos/microsoft.png" alt="Microsoft for Startup Logo" />
          <LogoReelItem src="/customer_logos/inits-logo.png" alt="Inits Logo" />
          <LogoReelItem src="/customer_logos/sciencepark_graz.png" alt="SPG Logo" />
          <LogoReelItem src="/customer_logos/dss-logo.jpg" alt="DSS Logo" />
          <LogoReelItem src="/customer_logos/sentinelhub_logo.svg" alt="SentinelHub Logo" />
        </LogoReel>
      </section>
      <section className="max-w-screen-2xl mx-auto my-20">
        <ContactUsBar intl={intl} />
      </section>
      <section className="max-w-screen-2xl mx-auto my-20">
        <div className="text-center my-6">
          <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
            <FormattedMessage id="index.heading-press" defaultMessage="Ubicube in the Press" />
          </h2>
          <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
            {intl.formatMessage({
              id: "index.heading-press-subtext",
              defaultMessage: "Find out more about ubicube through some independent press and media articles.",
            })}
            <Link to="/press" className="font-semibold">
              {" "}
              {intl.formatMessage({ id: "index.heading-press-link", defaultMessage: "Check out our press section." })}
            </Link>
          </p>
        </div>
        <LogoReel>
          <LogoReelItem alt="Brutkasten Logo" src="/press_logos/brutkasten_logo.svg" />
          <LogoReelItem alt="GEWINN Logo" src="/press_logos/Gewinn-Magazin-Logo.svg" />
          <LogoReelItem alt="Austria in Space Logo" src="/press_logos/austria-in-space.svg" />
          <LogoReelItem alt="DIO Logo" src="/press_logos/Logo_DIO_Web.png" />
        </LogoReel>
      </section>
    </Layout>
  );
};

export default IndexPage;
