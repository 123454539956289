import React from "react";

export function LogoReel({ intl, children }) {
  return <div className="flex flex-wrap items-center justify-center">{children}</div>;
}

export function LogoReelItem({ src, alt }) {
  return (
    <div className="flex-grow my-12 flex-shrink w-40 text-center p-2">
      <img src={src} alt={alt} className="mx-auto max-w-[8rem] max-h-24 grayscale" />
    </div>
  );
}
